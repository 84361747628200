import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Hr, Strong, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Гольф-клуб Вітер
			</title>
			<meta name={"description"} content={"Вирушайте в першокласну подорож у гольф у Гольф-клуб Вітер, де традиції поєднуються з сучасною розкішшю серед природи."} />
			<meta property={"og:title"} content={"Гольф-клуб Вітер"} />
			<meta property={"og:description"} content={"Вирушайте в першокласну подорож у гольф у Гольф-клуб Вітер, де традиції поєднуються з сучасною розкішшю серед природи."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12:57:14.342Z"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link5" />
			<Override slot="link4" />
			<Override slot="text1" />
			<Override slot="text" />
			<Override slot="box3" />
		</Components.Header>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 80px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				md-margin="0px 0px 50px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Ласкаво просимо до Гольф-клуб Вітер!
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL1" font="--lead" lg-text-align="center">
					Вирушайте в першокласну подорож у гольф у Гольф-клуб Вітер, де традиції поєднуються з сучасною розкішшю серед природи. Наш клуб є ідеальним місцем для тих, хто прагне насолоджуватися спортом з відтінком елегантності. Оточений захоплюючими краєвидами, Гольф-клуб Вітерстане вашими воротами до кардинального досвіду гри в гольф.
				</Text>
			</Box>
			<Box
				width="50%"
				padding="0px 0px 0px 32px"
				lg-width="100%"
				display="flex"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(3, 180px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="32px"
					max-width="600px"
					md-grid-template-rows="repeat(3, 25vw)"
					sm-grid-gap="16px"
				>
					<Image
						src="https://uploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-3.jpg?v=2024-06-04T12:57:14.374Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="1 / span 1"
						grid-row="1 / span 2"
						srcSet="https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-3.jpg?v=2024-06-04T12%3A57%3A14.374Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-3.jpg?v=2024-06-04T12%3A57%3A14.374Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-3.jpg?v=2024-06-04T12%3A57%3A14.374Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-3.jpg?v=2024-06-04T12%3A57%3A14.374Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-3.jpg?v=2024-06-04T12%3A57%3A14.374Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-3.jpg?v=2024-06-04T12%3A57%3A14.374Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-3.jpg?v=2024-06-04T12%3A57%3A14.374Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-3.jpg?v=2024-06-04T12:57:14.323Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="2 / span 1"
						grid-row="2 / span 2"
						srcSet="https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-3.jpg?v=2024-06-04T12%3A57%3A14.323Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-3.jpg?v=2024-06-04T12%3A57%3A14.323Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-3.jpg?v=2024-06-04T12%3A57%3A14.323Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-3.jpg?v=2024-06-04T12%3A57%3A14.323Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-3.jpg?v=2024-06-04T12%3A57%3A14.323Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-3.jpg?v=2024-06-04T12%3A57%3A14.323Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-3.jpg?v=2024-06-04T12%3A57%3A14.323Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 50px 0" sm-padding="40px 0 30px 0" quarkly-title="Statistics-11">
			<Override
				slot="SectionContent"
				align-items="flex-start"
				flex-direction="row"
				md-flex-wrap="wrap"
				max-width="1440px"
			/>
			<Box width="49%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text letter-spacing="-.06em" color="--dark" margin="0px 0px 0px 0px" font="--headline3">
					Винятковий дизайн поля
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					кожна лунка на Гольф-клуб Вітер— це унікальне випробування на тлі природної краси, створене для перевірки ваших навичок і винагороди за точність
				</Text>
			</Box>
			<Box width="49%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text margin="0px 0px 0px 0px" font="--headline3" letter-spacing="-.06em" color="--dark">
					Комплекс зручностей
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text color="--dark" margin="20px 0px 0px 0px" font="--base">
					насолоджуйтеся доступом до нашого сучасного тренувального рейнду, лужок для гольфу та затишного клубу, де ви можете відпочити та поділитися історіями
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" background="--color-lightD1" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12:57:14.342Z"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						srcSet="https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12%3A57%3A14.342Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12%3A57%3A14.342Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12%3A57%3A14.342Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12%3A57%3A14.342Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12%3A57%3A14.342Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12%3A57%3A14.342Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12%3A57%3A14.342Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="--headline3" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Спільнота та мережа
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							станьте частиною спільноти, яка цінує спортивну майстерність і зв’язок, з регулярними турнірами та ексклюзивними подіями для учасників.
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							<Span
								font="--headline3"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Strong>
									Гольф-клуб Гольф-клуб Вітер— це більше, ніж місце розташування, це спосіб життя, який ставить вас і вашу пристрасть до гольфу на перший план.
								</Strong>
							</Span>
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-17">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-grid-template-columns="repeat(2, 1fr)"
				grid-auto-flow="row"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="10px 10px"
				grid-template-rows="auto"
				lg-grid-template-rows="repeat(4, 117px)"
				lg-align-self="center"
				lg-grid-gap="36px"
				width="100%"
				display="grid"
				padding="16px 0px 16px 0px"
				lg-grid-template-columns="repeat(4, 1fr)"
			>
				<Box
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					justify-content="space-between"
					lg-padding="16px 16px 16px 16px"
					display="flex"
					flex-direction="row"
					lg-width="100%"
					padding="16px 0 16px 0"
					lg-order="1"
					width="100%"
					align-items="flex-start"
					sm-margin="0px 0px 0px 0px"
				>
					<Box display="flex" flex-direction="column">
						<Text
							margin="0px 0px 0px 0px"
							color="--darkL2"
							font="--lead"
							lg-text-align="center"
							md-text-align="left"
							width="100%"
						>
							Гольф-клуб «Грін Віста» є взірцем досконалості гри в гольф. З полями, які переплітаються з природною мальовничою красою, наші об’єкти створені, щоб кинути виклик і задовольнити гравців у гольф будь-якого рівня кваліфікації. Від гри на чемпіонаті до затишного клубу, кожен аспект Гольф-клуб Вітеррозроблено з урахуванням гравців у гольф.
						</Text>
					</Box>
				</Box>
				<Box
					padding="0px 0px 0px 0px"
					justify-content="center"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					display="flex"
					position="relative"
					hover-transform="translateY(-10px)"
					lg-display="flex"
					align-items="center"
				>
					<Image
						width="100%"
						top="auto"
						right={0}
						src="https://uploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-1.jpg?v=2024-06-04T12:57:14.325Z"
						position="relative"
						display="block"
						left={0}
						bottom="0px"
						min-height="100%"
						height="112px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-1.jpg?v=2024-06-04T12%3A57%3A14.325Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-1.jpg?v=2024-06-04T12%3A57%3A14.325Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-1.jpg?v=2024-06-04T12%3A57%3A14.325Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-1.jpg?v=2024-06-04T12%3A57%3A14.325Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-1.jpg?v=2024-06-04T12%3A57%3A14.325Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-1.jpg?v=2024-06-04T12%3A57%3A14.325Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-1.jpg?v=2024-06-04T12%3A57%3A14.325Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					padding="0px 0px 0px 0px"
					align-items="center"
					display="flex"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					transition="transform 0.2s ease-in-out 0s"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-1.jpg?v=2024-06-04T12:57:14.335Z"
						object-fit="cover"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						height="112px"
						position="relative"
						display="block"
						width="100%"
						right={0}
						srcSet="https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-1.jpg?v=2024-06-04T12%3A57%3A14.335Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-1.jpg?v=2024-06-04T12%3A57%3A14.335Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-1.jpg?v=2024-06-04T12%3A57%3A14.335Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-1.jpg?v=2024-06-04T12%3A57%3A14.335Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-1.jpg?v=2024-06-04T12%3A57%3A14.335Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-1.jpg?v=2024-06-04T12%3A57%3A14.335Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-1.jpg?v=2024-06-04T12%3A57%3A14.335Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
			<Override slot="link2" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc440072400020e9c6f3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});